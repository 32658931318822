@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-weight: 420;
  color: #052533;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: manipulation;
}

@media (max-device-width: 1024px) {
  body {
    background-color: #052533;
  }
}
