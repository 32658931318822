.dialog-animation-enter.dialog-bg,
.dialog-animation-enter .dialog-bg {
	opacity: 0;
}
.dialog-animation-enter-active.dialog-bg,
.dialog-animation-enter-active .dialog-bg {
  opacity: 1;
  transition: opacity 300ms;
}
.dialog-animation-exit.dialog-bg,
.dialog-animation-exit .dialog-bg {
	opacity: 1;
}
.dialog-animation-exit-active.dialog-bg,
.dialog-animation-exit-active .dialog-bg {
  opacity: 0;
  transition: opacity 300ms;
}

.dialog-animation-enter .dialog-body {
  opacity: 0;
  transform: scale(.75);
}
.dialog-animation-enter-active .dialog-body {
  opacity: 1;
  transform: scale(1);
  transition: transform .3s cubic-bezier(0.175,0.885,0.32,1.275), opacity .3s linear;
}
.dialog-animation-exit .dialog-body {
  opacity: 1;
  transform: scale(1);
}
.dialog-animation-exit-active .dialog-body {
  opacity: 0;
  transform: scale(.75);
  transition: transform .15s ease-in, opacity .15s linear;
}