@media (min-width:481px)  {
  .stream-col {
    height: 100%;
  }
  .suggestions-modal-autocomplete .ant-modal-content {
    height: 30em;
  }
  .suggestions-modal-autocomplete .ant-modal-content .ant-modal-body {
    height: calc(100% - 3.5rem);
    font-size: 1.25em;
    line-height: 1.25em;
  }
  .no-more-content-container.stream-row {
    width: 97.5%;
  }
  .no-more-content {
    font-size: 11px;
    padding: 3em;
  }
  .scrolling-container,
  .right-col-wrapper,
  .right-col-wrapper>div {
    height: calc(100% - 2.607em);
  }
  /* BLUE NAV */
  .top-nav-bar .logo {
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
    height: 3em;
    width: 3.6em;
  }

  .side-blue-nav {
    width: 3.6em;
    height: 100%;
    float: left;
    background-color: #f7f7f7;
    overflow: hidden;
  }
  /* .blue-nav-button:hover {
    background-color: rgba(0, 0, 0, 0.05);
  } */
  .blue-nav-button {
    padding: 12px 19px;
    font-size: 11px;
    align-self: center;
    overflow: hidden;
    display: block;
  }

  .blue-nav-button.disabled {
    opacity: 0.25;
  }
  /* .blue-nav-button:hover {
    background-color: rgba(0, 0, 0, 0.05);
  } */

  /* a.active-nav-link.blue-nav-button {
    background-color: rgba(0, 0, 0, 0.1);
  } */

  .container {
    height: calc(100% - 3em)
  }


  /* a.active-nav-link:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-right: 8px solid #f5f5f5;
    border-bottom: 15px solid transparent;
    left: 42px;
  } */

  /* END OF BLUE NAV */

  /**/
  .top-nav-bar {
    height: 3em;
    line-height: 3em;
    background: #052533;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    /* border-bottom: 1px solid rgb(146, 146, 154); */
  }
  .ant-menu-submenu-popup {
    top: 75px !important;
    left: auto !important;
    right: 5px !important;
  }

  /**/
    /* SEARCHBAR */

  .suggestion-search-wrapper {
    background-color: #052533;
    margin: 0 0 0 10px;
    height: 12px;
  }

  .team-name {
    color: #a0a0a0;
  }
  .list-element:hover .team-name {
    color: #fff;
  }

  .suggestion-search-wrapper input.ant-input.ant-select-search__field {
    background: #052533 !important;
    color: #fff;
  } 

  .top-nav-bar-autocomplete input.ant-input {
    font-size: 12px;
    width: 25em;
  }

  .ant-modal-header .no-lens .ant-input-suffix i.anticon.anticon-search.ant-input-search-icon {
    display: none;
  }

  /* .ant-modal-header .ant-modal-title .ant-input-affix-wrapper input:hover, */
  .top-nav-bar-autocomplete input.ant-input:hover {
    border-bottom: solid 1px #fff !important;
  }

  .top-nav-bar-autocomplete .ant-select-selection__placeholder, .ant-select-search__field__placeholder {
    color: #ffffff !important;
    z-index: 2;
    
  } 

  i.anticon.anticon-close-circle.ant-select-clear-icon {
    background: #052533;
    color: white;
  }

  .top-nav-bar-autocomplete .ant-select-auto-complete.ant-select .ant-select-selection--single {
    background-color: #052533;
  }
  .ant-select-auto-complete.ant-select .ant-input {
    border: none;
  }
  
  .certain-category-search.ant-select-auto-complete .ant-input-affix-wrapper .ant-input-suffix {
    right: 12px;
  }
  .ant-modal-header .ant-modal-title .ant-input-affix-wrapper input{
    width: 95% !important;
    border-top: none;
    border-left: none;
    border-radius: 0;
    border-right: none;
  }
  .ant-modal-header .ant-modal-title .ant-input-affix-wrapper input:focus,
  .ant-modal-header .ant-modal-title .ant-input-affix-wrapper input:active,
  .ant-modal-header .ant-modal-title .ant-input-affix-wrapper input:hover {
    border-bottom: solid 1px #d9d9d9;
  }
  
  .certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
    color: #666;
    font-weight: bold;
  }


  .left-col-wrapper {
    flex-wrap: nowrap;
    width: 12em;
    border-right: none;
    background: #fff;
    height: 100%;
  }
  .left-col-wrapper-list {
    overflow-y: auto;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .ant-tabs-content.ant-tabs-top-content {
    display: flex;
    height: calc(100% - 3em);
  }
  .two-cols>div,
  .livestream-container {
    height: 100%;
  }
  .ant-menu-vertical .ant-menu-item:not(:last-child), .ant-menu-vertical .ant-menu-item {
    background-color: #052533 !important;
  }
  .ant-menu-dark.ant-menu-horizontal,
  .ant-dropdown-placement-bottomCenter .ant-dropdown-menu {
    background-color: #052533;
  }
    
    /* DETAIL */

  .fulltext-detail-wrapper,
  .hashtag-detail-wrapper,
  .detail-wrapper {
    border-bottom: 1px solid #d5d5d5;
    padding-bottom: 1.5em;
  }
  .detail-row {
    flex-wrap: wrap;
    padding-bottom: 0;
  }
  .fulltext-detail-picture,
  .hashtag-detail-picture,
  .detail-picture {
    height: 49px;
    margin: 2em 10px 0 20px;
  }
  .detail-meta-wrapper {
    max-width: 120px;
  }

  .fader {
    position: fixed;
    height: 2.5em;
    width: 11.6em;
    background: -webkit-linear-gradient(#fff, rgba(245, 245, 245, 0));
    background: linear-gradient(#fff, rgba(245, 245, 245, 0));
    margin-top: -15px;
  }
  .meta-container {
    font-size: 14px;
    margin-top: 1.9em;
    text-align: left;
    font-weight: bold;
    color: #052533;

  }
  .ant-menu-submenu-title {
    font-size: 14px;
    padding-right: 0;
  }
  .description-wrapper {
    font-size: 10px;
  }

  /* .hashtag-detail-row,
  .detail-social-wrapper {
    flex-wrap: wrap;
  } */

  .detail-icon {
    font-size: 28px;
    

    /* color: #1890ff; */
  }
  .hashtag-detail-picture,
  .fulltext-detail-picture {
    width: 3em;
  }
  .fulltext-detail-wrapper,
  .hashtag-detail-wrapper,
  .favourite-detail-container {
    border-bottom: 1px solid #d5d5d5;
    min-height: 5em;
  }
  .bookmarked-icon {
    width: 2em;
  }
  .favourite-detail-icon {
    font-size: 28px;
    width: 1em;
    height: 1em;
    color: #13bee8;
    margin: 2em 10px 0 20px;
  }
  .profile-picture {
    margin-bottom: 20px;
    display: block;
    margin-top: 20px;
  }
  .favourite-detail-label {
    font-size: 14px;

    /* margin-top: 15px;
    text-align: left; */
  }

  /* END OF DETAIL */

  .ant-modal-header {
    border: none;
    padding-top: 0px;
  }

  .ant-modal-title a {
    padding: 0 15px 0 0;
  }

  .title-row {
    margin-bottom: 1em;
    display: block;
    margin-top: 1em;
  }

  .title-row img {
    vertical-align: middle;
    display: inline;
  }

  .title-name {
    display: inline;
    font-size: 1.5em;
    /* margin-top: 0.3em; */
  }
  .ant-modal-body span {
    font-size: 12px;
  }
  .ant-modal-body .list-element span {
    font-size: 10px;
  }
  .ant-modal-content {
    /* padding: 15px; */
    border: none;
  }

  .ant-modal-body {
    padding-top: 0px;
  }

  .ant-modal-footer {
    text-align: left;
    border: none;
    /* font-size: 18px; */
    padding: 1em 2em;
  }
  /* CLOCK COMPONENT */
  .clock {
    font-family: 'Roboto', sans-serif;
    padding-left: 1em;
    font-size: 0.9em;
    font-weight: 100;
    line-height: 3.9em;
    color: #8599a8;
  }
  :root .ant-dropdown-trigger > .anticon.anticon-down, :root .ant-dropdown-link > .anticon.anticon-down {
    font-size: 10px;
    margin-left: 4px;
  }

  .ant-select {
    border-bottom: 1px solid #fff;
    width: 12em;
  }

  /* HORIZONTAL MENU */
  .ant-menu-horizontal {
    background: #ff000000;
    color: #FFF;
    border: none;
  }

  .ant-menu-horizontal > .ant-menu-item:hover,
  .ant-menu-horizontal > .ant-menu-item-active,
  .ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
    color: #FFF;
  }
  .ant-menu-dark.ant-menu-horizontal {
    margin-top: 0px;
    /* font-size: 13px; */
  }
  /* .filter-bar {
    max-height: 51px;
    margin-left: 9px;
    position: fixed;
    background: #fff0;
    top: 6.2em;
    z-index: 1;
  } */

  .ant-menu-item:hover,
  .ant-menu-item-active,
  .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-submenu-active,
  .ant-menu-submenu-title:hover {
    color: #FFF;
  }


  /* TABS */
  .ant-tabs-bar {
    border-color: #d5d5d5;
    width: 100%;
    /* min-height: 50px; */
  }
  .ant-tabs-nav .ant-tabs-tab{
    margin: 0 4px 0 0;
    padding: 6px;
    color: #13bee8;
    font-size: 12px;
  }
  .detail-picture img.profile-image {
    font-size: 29px;
    height: 29px;
  }
  .list-element img.profile-image {
    /* max-width: 3em; */
    object-fit: contain;
    margin-right: 10px;
    vertical-align: bottom;
  }
  .detail-row .detail-picture {
    margin-top: 2em;
  }
  /* .profile-image {
    height: 25px;
    margin: 0 10px 0 5px;
    vertical-align: bottom;
  } */
  .ant-dropdown-button {
    margin-right: 5px;
  }

  .ant-tabs-tab-active.ant-tabs-tab {
    padding: 12px 6px;
    font-weight: 600;
  }

  span.ant-tabs-tab-prev.ant-tabs-tab-btn-disabled.ant-tabs-tab-arrow-show {
    display: none;
  }

  .ant-tabs-content.ant-tabs-top-content{
    height: calc(100% - 3em);
    width: 100%;
  }
  .ant-tabs-ink-bar {
    background-color: #13bee8;
  }
  /* .ant-tabs-nav .ant-tabs-tab-active {
  } */


  .ant-tabs-bar {
    margin-bottom: 0;
  }
  /* profile tab */
  /* .subtitle-wrapper {
    vertical-align: sub;
  } */
  .ant-badge {
    margin-left: 10px;
    vertical-align: super;
    font-size: 0.5em;
  }

  .ant-menu-submenu-title .anticon {
    margin: 0;
  }
  .ant-avatar.ant-avatar-icon {
    margin-right: 0;
  }
  .ant-menu-dark, .ant-menu-dark .ant-menu-sub{
    margin-top: -11px;
  }

  /* end profile tab */

  /* DETAIL */
  .hashtag-detail-wrapper,
  .fulltext-detail-wrapper {
    min-height: 7em;

  }
  .detail-wrapper {
    border-bottom: 1px solid #d5d5d5;
    padding-bottom: 1.5em;
  }
  .detail-row {
    flex-wrap: wrap;
    /* padding-bottom: 1.5em; */
  }
  .fulltext-detail-picture img,
  .hashtag-detail-picture img,
  .detail-picture {
    height: 39px;
    margin: 15px 10px 0 20px;
  }
  .detail-meta-wrapper {
    max-width: 240px;
  }
  .fulltext-detail-row,
  .hashtag-detail-row,
  .detail-social-wrapper {
    flex-wrap: wrap;
  }
  .detail-icon {
    /* font-size: 30px; */
    color: #13bee8;
  }

  .favourite-detail-label {
    /* font-size: 16px; */
    margin-top: 15px;
    text-align: left;
  }

  /* END OF DETAIL */

  .scrolling-container {
    overflow: auto;
    /* height: 892px; */
    width: 100%;
  }

  .right-col-wrapper {
    width: calc(100% - 12em)
  }

  .ant-modal-close {
    top: 1em;
    right: 1em;
  }

  /* STREAMS */
  .stream-message {
    line-height: 1.5em;
  }
  .stream-row {
    height: 9.7em;
    max-height: 9.7em;
    padding: 1em;
    font-size: 12px;
    position: relative;
    border-radius: 2px;
    /* border-right: 1px solid #92929a8f;
    border-top: 1px solid #92929a8f; */
    /* margin-right: 5px; */
    overflow: hidden;
    color: #000;
    border-left: 2px solid transparent;
  }
  .loading {
    height: 4.7em;
  }
  .stream-source-type {
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 1em;
  }
  .fading-message::after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 70%;
    height: 2.4em;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff 50%);
  }

  .stream-row:hover {
    border-left: 2px solid #000 !important;
    cursor: pointer;
  }

  .streams-col-wrapper {
    border-radius: 2px;
    overflow-y: auto;
    border-left: 1px solid #92929a8f;
    width: 100%;
    /* height: 100%;   */
  }
  .preview-picture-row img {
    height: 2.7em;
    width: 2.7em;
    margin-right: .9em;
  }


  .stream-with-preview-image {
    display: inline-flex;
    flex-direction: column;
    width: 68%;
    vertical-align: top;
    height: 100%;
    /* padding: 10px; */
  }
  .stream {
    display: inline-flex;
    flex-direction: column;
    vertical-align: top;
    height: 100%;
  }

  .preview-image-container {
    position: absolute;
    padding: 0;
    right: 0;
    bottom: 0;
    height: 10em;
    width: 10em;
  }

  .two-cols-preview-image-container {
    /* max-height: 130.5px; */
    width: 100%;
    height: 100%;
  }
  .loading-container {
    bottom: 40px;
    width: 100%;
    text-align: center;
  }
  /* END OF STREAMS*/
  /* BOOKMARKED */
  .bookmarked-header {
    min-height: 5em;
    padding: 1.5em 1.5em;
  }
  .bookmarked-header i {
    font-size: 20px;
  }
  .bookmarked-header h1 {
    display: inline;
    vertical-align: text-bottom;
    font-size: 15px;
    margin-left: 10px;
  }
  .bookmarked-body {
    background-color: #fff ;
    /* box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.16); */
    padding: 10px;
  }
  .bookmarked-body .livestream-container {
    width: 100%;
  }
  .bookmarked-wrapper .title-wrapper {
    display: none;
  }

  /* END OF BOOKMARKED*/
  /* INFO MESSAGE */
  .not-visible {
    display: none;
    opacity: 0;
    zoom: 1;
    transform: scale(0);
    transition: all 218ms cubic-bezier(0.55, 0, 0.1, 1);
  }

  .notification-wrapper .visible:hover {
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12), 0 3px 1px -2px rgba(0,0,0,.2);
  }
  .notification-wrapper .visible {
    transition: all 218ms cubic-bezier(0.55, 0, 0.1, 1);
    transform: scale(1);
    opacity: 1;
    border-radius: 3px;
    cursor: pointer;
    background: #13bee8;
    color: #fff;
    padding: .7em 3em;
    text-align: center;
    z-index: 10;
    /* padding: 8px 15px; */
    font-size: 10px;
    /* box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12), 0 3px 1px -2px rgba(0,0,0,.2); */
  }
  .blue-nav-button i {
    font-size: 1em;
  }
  #scroller .stream-col .title-wrapper-row {
    height: 2em;
  }
  .notification-wrapper {
    position: fixed;
    display: flex;
    top: 6.3em;
    z-index: 0;
    background: #f7f7f7;
    justify-content: center;
  }
  .notification-wrapper .anticon-up {
    top: 0px;
    color: #fff;
    margin-right: 5px;
  }

  .two-cols .notification-wrapper {
    left: unset;
    top: 5.5em;
    width: 33.5%;
    margin: 0 auto;
    height: 29px;
    margin-top: 15px;
    background-color: #f7f7f7;
    display: flex;
    justify-content: center;
  }
  .two-cols .visible {
    display: flex;
    align-self: baseline;
  }

  /* DROPDOWN BUTTONS */
  button.ant-btn.ant-dropdown-trigger {
    margin-right: 10px;
    font-size: 10px;
    padding: 5px;
    height: 23px;
  }
  /* END OF DROPDOWN BUTTONS */
  .list-element a,
  .section-title {
    font-size: 10px;
  }
  .go-back {
    margin-left: 12em;
  }
  .go-back button {
    font-size: 10px;
  }

  /* CHARTS */
  .line-wrapper,
  .pillar {
    width: 100%;
    padding: 1em;
    border-top: 1px solid #d5d5d5;
  }
  .line-title,
  .cloud-title,
  .pillar-title,
  .donut-title,
  .geo-title {
    font-size: 1em;
    /* margin-top: 20px; */
    margin-bottom: 30px;
  }
  .line-title { margin-bottom: 0 !important; }
  .line-subtitle {
    display: inline-block;
    color: black;
    margin-bottom: 30px;
  }

  .doughnut-wrapper {
    min-height: 280px;
    /* max-width: 240px; */
    width: 100%;
  }
  #reactgooglegraph-1 {
    width: 100%px;
  }
  .donut {
    margin-left: 0 !important;
  }
  .geo {
    margin-left: 16px !important;
  }
  .pillar-chart.stacked {
    margin-bottom: 22vh;
  }
  .line-chart,
  .pillar-chart {
    position: relative;
    height: 40.7vh;
    margin-bottom: 10vh;
  }
  .line-chart,
  .pillar-chart,
  .pillar {
    width: 100%;
  }
  .hashtag-cloud {
    width: 100%;
    margin: 0 40px;
    margin-bottom: 100px;
  }
  .no-stats {
    color: black;
    margin: 0 auto;
    text-align: center;
    border: dashed 1px #c2c2c6;
  }
  .line-chart .no-stats,
  .pillar-chart .no-stats {
    /* width: 450px; */
    width: 100%;
  }

  /* .hidden {
    width: 0;
  } */

  .overlay {
    width: 100%;
    height: 96.2%;
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    z-index: 10;
  }

  .overlay .title {
    display: inline;
    vertical-align: baseline;
    padding: 0 140px;
  }

  .overlay .content {
    height: calc(100% - 3.5em);
    position: relative;
    overflow: hidden;
    overflow-y: auto;
  }

  .cross-feed-content {
    width: 40vw;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    transition: transform 218ms cubic-bezier(0.55, 0, 0.35, 1);
  }

  .overlay .cross-feed-content {
    padding: 10px;
    right: 0;
    }

  .hidden .cross-feed-content {
    transform: translateX(350px);
  }
  .cross-feed-x {
    font-size: 25px;
    font-weight: bold;
    padding: 10px;
    display: inline-flex;
    cursor: pointer;
  }
  .cross-feed-content .title-wrapper {
    vertical-align: super;
    display: inline;
  }

  /* END CHARTS */

  .ant-drawer.ant-drawer-open .ant-drawer-mask {
    top: 7.8em;
    left: 21.6em !important;
    opacity: 0.9 !important;
  }

  .ant-drawer-body {
    padding: 10px;
    padding-top: 0;
  }
  .info-drawer .ant-drawer-body {
    height: calc(100% - 7em);
  }

  .ant-drawer-title {
    text-align: center;
  }
  .title {
    display: inline;
    font-size: 11px;
  }
  .ant-drawer-content-wrapper {
    top: 6em;
  }
  .crossfeed-x {
    margin-right: 5px;
    font-weight: 600;
    cursor: pointer;
  }
  .header {
    position: fixed;
    background: #fff;
    height: 57px;
    width: 100%;
    z-index: 100;
  }
  .content {
    margin-top: 60px;
  }
  .ant-drawer-header {
    border: none;
  }
  p.description-type {
    font-size: 10px;
  }

  .no-streams {
    margin: 0 10px;
    padding: 2em;
    text-align: center;
    width: 95%;
    border-left: 0; 
  }
  .ant-drawer-content-wrapper {
    width: 35em !important;
  }

  .social-counter {
    margin-left: .4em;
    font-weight: 600;
    font-size: 10px;
  }

  #nprogress .spinner-icon {
    display: none;
  }
}






@media (min-width:641px)  {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .detail-icon {
    width: 1.5em;
  }
  .ant-menu-horizontal {
    line-height: 0;
  }

  .geo {
    width: 100%;
  }
  div#reactgooglegraph-1 {
    width: 100%;
  }
  .line-wrapper,
  .pillar {
    width: 100%;
  }
  .line-title,
  .cloud-title,
  .pillar-title,
  .donut-title,
  .geo-title {
    font-size: 1em;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .donut {
    width: 100%;
  }
  .doughnut-wrapper {
    max-width: 100%;
    /* min-height: 480px; */
  }
  .ant-drawer-content-wrapper {
    width: 39.3em !important;
  }

  .ant-drawer-body {
    height: calc(100% - 10em);
  }

  .ant-drawer-left.ant-drawer-open, .ant-drawer-right.ant-drawer-open,
  .ant-drawer-open .ant-drawer-mask {
    top: 6em;
    height: calc(100% - 6em);
  }
  .ant-drawer.ant-drawer-open .ant-drawer-mask {
    top: 6em;
    left: 21.6em !important;
    opacity: 0.9 !important;
  }
  .ant-drawer .scrolling-container {
    height: 100%;
  }
  .no-stats {
    /* padding: 203px 0; */
    padding: 20%;
  }

  /* .filter-bar button.ant-btn.ant-btn-primary {
    margin-right: 20px;
  } */
  .description-link-container {
    margin-left: -3em !important;
  }
  .profile-name {
    max-width: calc(100% - 3em);
    text-overflow: ellipsis;
  }
  .stream {
    width: 100%;
  }
  p.clock {
    min-width: 14em;
  }
  /* .profile-container {
    height: 2.9em !important;
  } */
  .ant-dropdown-placement-bottomLeft {
    top: 3em !important;
  }
  .ant-dropdown-placement-bottomCenter {
    top: 2.9em !important;
  }
  .ant-modal.social-post-modal {
    min-width: 40em;
  }
  .ant-modal-body .fader {
    top: auto;
    width: 36em;
  }
  .sentiment-placeholder {
    display: flex;
    width: 18em;
}

}







@media (min-width:961px)  {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .left-col-wrapper {
    flex-wrap: nowrap;
    width: 18em;
    border-right: none;
    background: #fff;
    height: 100%;
  }  
  .right-col-wrapper {
    width: calc(100% - 18em)
  }
  .notification-wrapper {
    top: 6.5em;
  }
  .two-cols .notification-wrapper {
    top: 7em;
    width: 33%;
  }
  #scroller .stream-col .title-wrapper-row {
    height: 3.7em;
  }
  .socal-types-col {
    margin: 0.5em 0
  }
  .ant-select {
    width: unset;
  }
  .title {
    font-size: 13px;
  }
  .ant-btn {
    height: 25px;
  }
  .side-blue-nav {
    width: 4.7em;
  }
  .top-nav-bar .logo {
    width: 4.7em;
    height: 4em;
  }
  .top-nav-bar {
    height: 4em;
    line-height: 4em;
  }
  .clock,
  .top-nav-bar-autocomplete input.ant-input {
    font-size: 14px;
  }
  p.clock {
    margin-top: 3px;
  }
  .notification-wrapper span.visible {
    font-size: 13px;
  }

  /* .filter-bar {
    top: 4.6em;
  } */
  .notification-wrapper {
    top: 8.2em;
  }
  
  .blue-nav-button {
    padding: 22px 19px;
  }
  /* a.active-nav-link:before {
    left: 58px;
  } */

  .top-nav-bar-autocomplete input.ant-input {
    width: 25em;
  }

  button.ant-btn.ant-dropdown-trigger {
    margin-right: 10px;
    font-size: 13px;
    padding: 5px 10px;
    height: auto;
  }

  .blue-nav-button i {
    font-size: 1.5em;
  }
  .go-back {
    margin-left: 12em;
  }
  .go-back button {
    font-size: 13px;
  }
  .fader {
    width: 17.5em;
  }

  .meta-container span {
    font-size: 18px;
  }
  .detail-picture img.profile-image {
    font-size: 39px;
    height: 39px;
  }
  .detail-row div:nth-child(2) {
    margin-top: 0;
  }
  .stream-source-type {
    height: 1em;
  }
  .social-counter {
    font-size: 15px;
    margin-left: 1em;
  }
  .social-counter span {
    font-size: 13px;
  }
  .section-title {
    font-size: 13px;
  }
  p.description-type {
    font-size: 11px;
  }
  .detail-picture svg {
    /* font-size: 39px; */
    width: 1.5em;
  }
  .profile-icon svg path,
  .detail-picture svg path {
    fill: #052533;
  }
  .ant-modal-body .list-element a {
    display: inline-flex;
    align-items: center;
  }
  .ant-modal-body .active .list-element a .profile-icon svg path {
    fill: #fff;
  } 
  .list-element a {
    font-size: 12px;
  }

  .ant-tabs-bar {
    /* border-color: #d5d5d5; */
    width: 100%;
    height: auto;
  }
  .ant-tabs-nav .ant-tabs-tab{
    margin: 0 4px 0 0;
    padding: 16px;
    font-size: 14px;
  }
  .ant-tabs-content.ant-tabs-top-content{
    width: 100%;
    height: calc(100% - 3.8em);
  }


  .ant-tabs-bar {
    margin-bottom: 0;
  }
  .description-wrapper {
    font-size: 13px;
  }
  .description-link-container {
    margin-top: 10px !important;
    margin-left: -3em !important;
  }
  .stream-with-preview-image {
    width: 75%;
  } 

  .preview-image-container {
    position: absolute;
    /* padding: 1em; */
    right: 0;
    bottom: 0;
    height: 8.5em;
    width: 8.5em;
  }
  .stream-with-preview-image span.stream-source-name {
    text-overflow: ellipsis;
    overflow: hidden;
    width: calc(100% - 3em);
  }

  .two-cols-preview-image-container {
    /* max-height: 130.5px; */
    width: 100%;
    height: 100%;
  }

  .sentiment-placeholder {
    display: flex;
    width: 24em;
  }

}










@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ 
  .meta-container {
    margin-top: 1.8em;
  }
  .ant-modal .title-name {
    margin-top: 0;
    line-height: 1;
    text-align: left;
    margin-left: 0;
  }
  .ant-modal-body span {
    font-size: 14px;
  }
  .ant-modal.social-post-modal {
    min-width: 50em;
  }

  .detail-icon {
    width: 1.5em;
  }
  
  .scrolling-container, .right-col-wrapper, .right-col-wrapper>div {
    height: calc(100% - 4.1em);
  }
  .ant-menu-dark.ant-menu-horizontal {
    margin-top: 0;
    max-height: 3.5em
  }

  .ant-modal-body .list-element span {
    font-size: 13px;
  }
  .ant-drawer.ant-drawer-open .ant-drawer-mask {
    top: 7.7em;
    left: 30.7em !important;
    opacity: 0.9 !important;
  }
  .ant-drawer-left.ant-drawer-open,
  .ant-drawer-right.ant-drawer-open,
  .ant-drawer-open .ant-drawer-mask {
    top: 7.7em;
    height: calc(100% - 7.7em);
  }
  .ant-drawer-content-wrapper {
    top: 7.7em;
  }
  .ant-drawer-body {
    height: calc(100% - 11.5em);
  }

  .ant-drawer-body>div {
    height: 100%;
  }
  .ant-modal-body .fader {
    width: 46em;
    top: auto;
  }
  /* .fader {
    width: 23.5em;
  } */

  .stream-row {
    height: 8.5em;
    max-height: 8.5em;
  }
  .stream-message {
    font-size: 13px;
  }
  .fading-message-container {
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5em;
  }
  .ant-dropdown-placement-bottomCenter {
    top: 4em !important;
  }
  .no-more-content {
    font-size: 13px;
  }
  .no-more-content-container.stream-row {
    width: 98.8%;
    /* border: 1px solid #c2c2c6 */
  }
  .two-cols .notification-wrapper {
    width: 36%;
  }

}









@media (min-width:1281px) { /* hi-res laptops and desktops */ 
  .top-nav-bar .logo {
    height: 4.25em;
    width: 5em;
  }
  .meta-container {
    margin-top: 1.9em;
  }
  .side-blue-nav {
    width: 5em;
  }
  .top-nav-bar {
    height: 4.25em;
    line-height: 4.5em;
    font-size: 14px;
  }

  .top-nav-bar-autocomplete input.ant-input {
    font-size: 14px;
    width: 25em;
  }

  .ant-modal-header {
    padding-top: 0px;
  }

  .ant-modal-title a {
    padding: 0 15px 0 0;
  }

  .title-row {
    font-size: 18px;
  }

  .preview-image-container {
    height: 7.5em;
    width: 7.5em;
  }

  .title-name {
    font-size: 30px;
    /* margin-top: 0.5em;
    margin-left: 0.5em; */
  }
  .ant-modal-body span {
    font-size: 14px;
  }

  .ant-modal-content {
    padding: 0;
    border: none;
  }

  .ant-modal-body {
    font-size: 1.25em;
    line-height: 1.25em;
    padding: 0;
  }

  .ant-modal-footer {
    /* text-align: left; */
    /* border: none; */
    font-size: 18px;
    padding: 25px;
  }
  .suggestion-search-wrapper {
    margin: 0 0 0 15px;
  }

  .ant-select {
    /* border-bottom: 1px solid #fff; */
    width: 24em;
  }

  /* TABS */
  .ant-tabs-bar {
    /* border-color: #d5d5d5; */
    width: 100%;
    height: auto;
  }
  .ant-tabs-nav .ant-tabs-tab{
    margin: 0 4px 0 0;
    padding: 16px;
    font-size: 14px;
  }
  .ant-tabs-content.ant-tabs-top-content{
    width: 100%;
    height: calc(100% - 3.8em);
  }


  .ant-tabs-bar {
    margin-bottom: 0;
  }


  .ant-menu-dark.ant-menu-horizontal {
    margin-top: 0;
    font-size: 18px;
  }

  p.description-type {
    font-size: 13px;
  }
  .description-link-container {
    margin-top: 0.5em !important;
    margin-left: -3.5em !important;
  }

  .stream-message {
    margin: 1.4em 0;
    margin-top: 4px;
  }

  .ant-badge {
    vertical-align: super;
  }
  /* 

  /* DETAIL */
  .hashtag-detail-wrapper,
  .fulltext-detail-wrapper,
  .detail-wrapper {
    border-bottom: 1px solid #d5d5d5;
    padding-bottom: 1.5em;
  }
  .detail-row {
    flex-wrap: wrap;
  }
  .fulltext-detail-picture img,
  .hashtag-detail-picture img,
  .detail-picture {
    margin: 1.5em 10px 0px 20px;
  }
  .detail-meta-wrapper {
    max-width: 240px;
  }
  .meta-container {
    font-size: 21px;
    margin-top: 1.25em;
    text-align: left;
    line-height: 1.3em;
    font-weight: bold;
    color: #052533;
  }
  .description-wrapper {
    font-size: 14px;
  }
  .fulltext-detail-row,
  .hashtag-detail-row,
  .detail-social-wrapper {
    flex-wrap: wrap;
  }
  .hashtag-detail-picture .custom-icon,
  .fulltext-detail-picture .custom-icon,
  .detail-icon {
    /* font-size: 40px; */
    color: #13bee8;
  }
  .favourite-detail-icon svg path {
    fill: #13bee8;
  }
  .fader{
    position: fixed;
    width: 21em;
    background: -webkit-linear-gradient(#fff, rgba(245, 245, 245, 0));
    background: linear-gradient(#fff, rgba(245, 245, 245, 0));
  }

  .fulltext-detail-wrapper,
  .hashtag-detail-wrapper {
    border-bottom: 1px solid #d5d5d5;
    min-height: 7em;
  }
  .favourite-detail-container {
    min-height: 5.5em;
    border-bottom: 1px solid #d5d5d5;
  }

  .favourite-detail-icon {
    font-size: 40px;
    color: #13bee8;
    margin: 12px 10px 0 20px;
  }
  .favourite-detail-label {
    font-size: 21px;
    margin-top: 15px;
    text-align: left;
  }

  /* END OF DETAIL */

  .left-col-wrapper {
    /* box-shadow: 0 0 22px 0 rgba(0, 0, 0, 0.18); */
    flex-wrap: nowrap;
    width: 21em;
    border-right: none;
    background: #fff;
    height: 100%;
  }
  /* .left-col-wrapper-list {
    overflow-y: auto;
    margin-bottom: 15px;
  } */

  .scrolling-container {
    overflow: auto;
    height: 100%;
    width: 100%;
  }
  .two-chart-wrapper,
  .livestats,
  .charts-container,
  .charts-container .scrolling-container {
    width: 100%;
  }

  .right-col-wrapper {
    width: calc(100% - 21em)
  }

  .label-container {
    font-size: 13px;
  }

  /* STREAMS */

  .stream-row {
    height: 9.5em;
    max-height: 9.5em;
    font-size: 14px;
    padding: 1.25em
  }
  .stream-message {
    margin-top: 1.25em;
  }
  .preview-image-container {
    height: 9.5em;
    width: 9.5em;
    padding: 0;
  }
  .loading {
    height: 7em;
    padding: 0;
  }
  .fading-message::after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 70%;
    height: 2.8em;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff 50%);
  }


  .streams-col-wrapper {
    border-radius: 2px;
    overflow-y: auto;
    border-left: 1px solid #92929a8f;
    width: 100%;
    height: 100%;  
  }
  .preview-picture-row img {
    height: 2.4em;
    width: 2.4em;
    margin-right: .9em;
  }

  span.rss,
  span.fb,
  span.ig,
  span.tw {
    color: #616161;
    font-size: 0.75em;
  }
  .stream-with-preview-image {
    display: inline-flex;
    flex-direction: column;
    width: 75%;
    vertical-align: top;
    /* padding: 10px; */
  }
  .stream {
    display: inline-flex;
    flex-direction: column;
    vertical-align: top;
    padding: 0;
  }

  .two-cols-preview-image {
    height: 100%;
    width: 100%;
  }
  .loading-container {
    bottom: 40px;
    width: 100%;
    text-align: center;
  }
  /* END OF STREAMS*/
  /* BOOKMARKED */
  .bookmarked-header {
    min-height: 9em;
    padding: 1.5em 1.5em;
  }
  .bookmarked-header i {
    font-size: 40px;
    /* padding: 20px; */
  }
  .bookmarked-header h1 {
    display: inline;
    vertical-align: text-bottom;
    font-size: 25px;
    margin-left: 10px;
  }
  .bookmarked-body {
    background-color: #fff ;
    padding: 10px;
  }
  .bookmarked-body .livestream-container {
    width: 100%;
  }
  .bookmarked-wrapper .title-wrapper {
    display: none;
  }

  /* END OF BOOKMARKED*/
  /* INFO MESSAGE */
  .not-visible {
    display: none;
    opacity: 0;
    zoom: 1;
    transform: scale(0);
    transition: all 218ms cubic-bezier(0.55, 0, 0.1, 1);
  }

  .notification-wrapper .visible {
    transition: all 218ms cubic-bezier(0.55, 0, 0.1, 1);
    transform: scale(1);
    opacity: 1;
    border-radius: 3px;
    cursor: pointer;
    background: #13bee8;
    color: #fff;
    padding: .7em 3em;
    text-align: center;
    z-index: 10;
    font-size: 13px;
  }

  .notification-wrapper {
    position: fixed;
    height: 3em;
    left: unset !important;
    background: #f7f7f7;
    width: 70.5%;
    margin: 0 auto;
    text-align: center;
  }
  .notification-wrapper.visible {
    position: fixed;
    top: 9em;
  }
  .notification-wrapper .anticon-up {
    top: 0px;
    color: #fff;
    margin-right: 5px;
  }

  .two-cols .notification-wrapper {
    left: unset;
    top: unset;
    width: 34.5%;
    margin: 0 auto;
    height: 32px;
    margin-top: 12px;
    background: #f7f7f7;
    display: flex;
    align-content: center;
  }
  /* END INFO MESSAGE */

  .go-back {
    margin-left: 14em;
    margin-top: 0;
  }
  .go-back button {
    font-size: 14px;
  }
  /* DROPDOWN BUTTONS */
  button.ant-btn.ant-dropdown-trigger {
    margin-right: 10px;
    font-size: 14px;
    padding: 5px 10px;
  }
  /* END OF DROPDOWN BUTTONS */

  .blue-nav-button i {
    font-size: 1.5em;
  }
  .list-element a,
  .section-title {
    font-size: 13px;
    line-height: 1.8em;
  }
  .stream-source-type {
    font-size: 1.2em;
  }
  /* span.stream-source-type {
    margin-top: .2em;
  } */
  /* CHARTS */

  .geo {
    width: 100%;
  }
  div#reactgooglegraph-1 {
    width: 100%;
  }
  .line-wrapper,
  .pillar {
    width: 100%;
  }
  .line-title,
  .cloud-title,
  .pillar-title,
  .donut-title,
  .geo-title {
    font-size: 1.25em;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .donut {
    width: 100%;
  }


  .doughnut-wrapper {
    max-width: 100%;
    min-height: 480px;
  }
  .bookmarked-wrapper {
    height: 100%;
  }

  .no-stats {
    color: black;
    margin: 0 auto;
    padding: 203px 0;
    text-align: center;
    border: dashed 1px #c2c2c6;
  }
  .pillar-chart .no-stats {
    width: 100%;
  }

  .overlay {
    width: 100%;
    height: 96.2%;
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    z-index: 10;
  }

  .overlay .title {
    display: inline;
    vertical-align: baseline;
    padding: 0 140px;
  }

  .overlay .content {
    height: calc(100% - 3.5em);
    position: relative;
    overflow: hidden;
    overflow-y: auto;
  }

  .cross-feed-content {
    width: 40vw;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    transition: transform 218ms cubic-bezier(0.55, 0, 0.35, 1);
  }

  .ant-drawer .scrolling-container {
    height: 100%;
  }

  .overlay .cross-feed-content {
    padding: 10px;
    right: 0;
    }

  .hidden .cross-feed-content {
    transform: translateX(350px);
  }
  .cross-feed-x {
    font-size: 25px;
    font-weight: bold;
    padding: 10px;
    display: inline-flex;
    cursor: pointer;
  }
  .cross-feed-content .title-wrapper {
    vertical-align: super;
    display: inline;
  }

  /* END CHARTS */
  .ant-drawer-mask {
    top: 7.8em;
  }
  .ant-drawer.ant-drawer-open .ant-drawer-mask {
    left: 31em !important; 
    height: calc(100% - 7.8em);
    top: 7.8em;
    opacity: 0.9 !important;
  }
  .ant-drawer-content-wrapper {
    top: 7.8em;
  }
  .title {
    display: inline;
    font-size: 16px;
  }
  .header {
    position: fixed;
    background: #fff;
    height: 57px;
    width: 100%;
    z-index: 100;
  }
  .content {
    margin-top: 60px;
  }
  .ant-drawer-header {
    border: none;
  }
  /* .ant-drawer-left.ant-drawer-open, .ant-drawer-right.ant-drawer-open {
    width: 42em !important;
    left: 31em;
    height: calc(100% - 7.8em);
    top: 7.8em;
  } */
  .ant-drawer-content-wrapper {
    width: 42em !important;
  }
  .ant-drawer.ant-drawer-open.ant-drawer-mask,
  .ant-drawer-content-wrapper,
  .ant-drawer-left .ant-drawer-content-wrapper, .ant-drawer-right .ant-drawer-content-wrapper {
    height: calc(100% - 7.8em);
    top: 7.8em;
  }
  .ant-drawer-body {
    height: calc(100% - 3.8em)
  }
  .container {
    height: calc(100% - 4.25em)
  }
  .social-counter {
    font-size: 18px;
    margin-left: 1.4em;
  }
  .social-counter span {
    font-size: 16px;
  }

  .ant-menu-submenu-title {
    font-size: 17px;
    padding-right: 0;
  }
  .ant-modal-body span {
    font-size: 21px;
  }
  .ant-modal-body .list-element span {
    font-size: 14px;
  }

  .ant-drawer.ant-drawer-open .ant-drawer-mask {
    top: 7.8em;
    left: 25.9em !important;
    opacity: 0.9 !important;
  }
  .ant-drawer-left.ant-drawer-open,
  .ant-drawer-right.ant-drawer-open,
  .ant-drawer-open .ant-drawer-mask {
    top: 7.8em;
    height: calc(100% - 7.8em);
  }
  .ant-drawer-content-wrapper {
    top: 8em;
  }

  .no-more-content-container.stream-row {
    width: 99%;
    /* border: 1px solid #c2c2c6; */
  }
  .no-more-content {
    font-size: 13px;
    padding: 2em;
    height: 100%;
    text-align: center;
  } 

  .no-more-content-container.stream-row:hover {
    border-left: 1px solid #c2c2c6 !important;
    cursor: default;
  }
  .ant-modal-body .fader {
    width: 36em;
    top: auto;
  }
}












@media (min-width: 1530px) {
  .notification-wrapper {
    left: 66em;
  }
}
@media (min-width: 1650px) {
  .notification-wrapper {
    left: 70em;
  }
}
@media(min-width:1770px) {
  .notification-wrapper {
    left: 75em;
  }
}

@media (min-width: 1850px) { /* hi-res laptops and desktops */ 
  .two-cols .notification-wrapper {
    width: 38.5%;
    text-align: unset;
  }
}
@media (min-width: 1920px) {
  .notification-wrapper {
    width: 78%;
  }
}

@media (min-width: 2500px) {
  .notification-wrapper {
    width: 83.4%;
  }
  .two-cols .notification-wrapper {
    width: 41.5%;
    text-align: unset;
  }
  .ant-modal-body .fader {
    width: 36em !important;
  }
   span.stream-source-name {
    font-size: 1.1em;
  }
}

.ant-tabs-nav.ant-tabs-nav-animated {
  height: auto;
}

@media (max-width: 1024px) {
  .top-nav-bar-autocomplete {
    display: none;
  }
}