@import '~antd/dist/antd.css';
@import '~axios-progress-bar/dist/nprogress.css';
@import './animations.css';
@import './responsive.css';

#nprogress .spinner {
  display: none;
}

.App {
  text-align: center;
}

.ant-drawer .notification-wrapper {
  width: 40%;
}

.reach-wrapper,
.social-post-modal .ant-modal-footer,
.sentiment-slider-wrapper {
  display: flex;
}
.reach-wrapper {
  justify-content: center;
  align-items: center;
}
.social-post-modal .ant-modal-footer {
  justify-content: space-between;
}
.sentiment-slider-wrapper {
  width: 22em;
  align-items: center;
}
.ant-modal-footer span {
  align-self: center;
}

.reach {
  height: 1.2em;
  margin-right: 0.3em;
}
.preview-reach-icon {
  height: 1.2em;
}
.sentiment-icon {
  width: 1.2em;
  display: flex;
  font-size: 0.75em;
  margin-right: 0.3em;
}
.sentiment-icon svg,
.preview-reach-icon svg {
  width: 1.2em;
  margin-right: 0.2em;
}

.stream-source-type .reach {
  color: #b7b7b7;
  display: flex;
  font-size: 0.75em;
}
.sentiment-icon > div,
.preview-reach-icon > div {
  height: 1.2em;
}
/* span.reach-number {
  align-self: center;
} */
.stream-source-type .preview-reach-icon svg path {
  fill: #b7b7b7;
}
.sentiment-icon.up svg path {
  fill: #8edd05;
}
.sentiment-icon.doubleUp svg path {
  fill: #00d32d;
}
.sentiment-icon.neutral svg path {
  fill: #b7b7b7;
}
.sentiment-icon.down svg path {
  fill: #ff7300;
}
.sentiment-icon.doubleDown svg path {
  fill: #e8004d;
}
.thumb-up,
.thumb-down,
.reach-icon {
  height: 1.2em;
  width: 1.2em;
}
.reach-counter {
  color: #052533;
  align-self: center;
  margin-left: 0.5em;
  font-size: 14px;
}
.preview-picture-row > div > span {
  display: flex;
  align-items: center;
}
.thumb-down svg path {
  /* fill: #fa2b5d; */
  fill: #e8004d;
}
.thumb-up svg path {
  /* fill: #74e390; */
  fill: #00d32d;
}
.reach-icon svg path,
a.blue-nav-button svg path {
  fill: #052533;
}

a.blue-nav-button.active-nav-link svg path,
a.blue-nav-button:hover svg path {
  fill: #13bee8;
}

.blue-nav-button {
  width: 6em;
}

.blue-nav-button > div {
  width: 100%;
}
.ant-input-affix-wrapper:hover .ant-input {
  border-color: #fff !important;
}
.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 34px;
}
.team-name {
  color: #a0a0a0;
}
.list-element:hover .team-name {
  color: #fff;
}

.suggestion-search-wrapper input.ant-input.ant-select-search__field {
  background: #052533 !important;
  color: #fff;
}
.geo-chart-container path {
  stroke: #ccc;
}
.geo-chart-container circle {
  stroke: #13bee8;
}
.top-nav-bar-autocomplete {
  width: auto;
}
.top-nav-bar-autocomplete input.ant-input {
  background: transparent;
  color: #fff;
  border-color: #5e686d;
}

.top-nav-bar-autocomplete input.ant-input:hover {
  border-color: #fff;
}

.ant-modal-header .no-lens .ant-input-suffix i.anticon.anticon-search.ant-input-search-icon {
  display: none;
}

/* .ant-modal-header .ant-modal-title .ant-input-affix-wrapper input:hover, */
.top-nav-bar-autocomplete input.ant-input:hover {
  border-bottom: solid 1px #fff !important;
}

.top-nav-bar-autocomplete .ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  color: #ffffff !important;
  z-index: 2;
}

i.anticon.anticon-close-circle.ant-select-clear-icon {
  background: #052533;
  color: white;
}

.top-nav-bar-autocomplete .ant-select-auto-complete.ant-select .ant-select-selection--single {
  background-color: #052533;
}
.ant-select-auto-complete.ant-select .ant-input {
  border: none;
}

.certain-category-search.ant-select-auto-complete .ant-input-affix-wrapper .ant-input-suffix {
  right: 12px;
}
.ant-modal-header .ant-modal-title .ant-input-affix-wrapper input {
  width: 95% !important;
  border-top: none;
  border-left: none;
  border-radius: 0;
  border-right: none;
}
.ant-modal-header .ant-modal-title .ant-input-affix-wrapper input:focus,
.ant-modal-header .ant-modal-title .ant-input-affix-wrapper input:active,
.ant-modal-header .ant-modal-title .ant-input-affix-wrapper input:hover {
  border-bottom: solid 1px #d9d9d9;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
  color: #666;
  font-weight: bold;
}

#root {
  width: 100%;
  height: 100%;
}

/* LOGIN and FORM */
.reset-password,
.form-wrapper {
  height: 100%;
  width: 100%;
}
div#registration-logo,
div#reset-pwd-logo,
div#login-logo {
  width: 100%;
  margin: 0 auto;
  /* margin-left: 48em; */
}
.registration .ant-col.ant-form-item-label.ant-col-xs-24.ant-col-sm-8 {
  width: auto;
}
.registration a {
  color: #13bee8;
}
.registration .ant-select-selection:hover,
.registration .ant-checkbox:hover .ant-checkbox-inner {
  border-color: #13bee8;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #13bee8;
}

.registration button.ant-btn.ant-btn-primary {
  float: none;
  margin-top: 2em;
  width: 12em;
  height: 2.2em;
  border-radius: 0.3em;
  /* background: transparent; */
  margin-top: 1.5em;
  /* border-color: #13bee8;
  background-color: #13bee8; */
}
.registration .ant-form-item-control-wrapper {
  margin-left: 0;
}
.registration .ant-form-item-label {
  text-align: left;
  /* color: #13bee8; */
}
#registration-logo {
  height: 9em;
}
.registration-form input,
.reset-password input,
.login-form input {
  background: transparent;
  border: none;
  border-bottom: 1px solid #13bee8;
  border-radius: 0;
  color: #13bee8;
}

.reset-password-form input:-webkit-autofill {
  width: 70%;
  text-align: center;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  box-shadow: 0 0 0 30px #fff inset !important;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #13bee8 !important;
}
.login-form .anticon-lock svg,
.login-form .anticon-user svg {
  color: #13bee8;
}

.form-wrapper .ant-form-inline .ant-form-item {
  width: 15em;
  margin: 0 auto;
}
.ant-row.ant-form-item.recovery-password,
.ant-row.ant-form-item.register {
  width: auto;
  float: left;
}
.username-container.ant-form-item,
.password-input-container.ant-form-item {
  width: 26em;
}

.registration .ant-input:focus,
.reset-password .reset-password-form .ant-input:focus {
  border-bottom: 1px solid #13bee8;
}
.button-container .recovery-button button {
  float: none;
  margin-top: 2em;
  width: 12em;
  height: 2.2em;
  border-radius: 0.3em;
  background: transparent;
  margin-top: 1.5em;
}
.button-container .recovery-button[disabled]:hover,
.button-container.ant-row.ant-form-item button[disabled]:hover,
.button-container.ant-row.ant-form-item[disabled]:hover,
.button-container .recovery-button:disabled,
.button-container.ant-row.ant-form-item button:disabled,
.button-container.ant-row.ant-form-item:disabled,
.button-container .recovery-button button.ant-btn.ant-btn-button:disabled,
.button-container .recovery-button button.ant-btn.ant-btn-button[disabled]:hover,
.button-container .recovery-button button.ant-btn.ant-btn-button[disabled] {
  color: #cfc7cf;
  border-color: #cfc7cf;
  cursor: not-allowed;
  background: #fff;
  border: solid 1px;
}
.button-container .recovery-button button.ant-btn-button:hover {
  color: #fff;
  background: #13bee8;
  border: #fff;
}
.favourite-detail-icon svg path {
  fill: #13bee8;
}
.favourites-modal button.ant-btn.modal.grey-button {
  height: 32px;
  width: 100%;
  text-transform: uppercase;
  background-color: #92929a;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
  border-color: transparent;
}
.favourites-modal button.ant-btn.modal.grey-button:hover {
  background-color: #787881;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
}
.favourites-modal button.ant-btn.modal.red-button {
  height: 32px;
  width: 100%;
  text-transform: uppercase;
  background-color: red;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
  border-color: transparent;
  margin: 0 !important;
  margin-top: 1em !important;
}
.favourites-modal button.ant-btn.modal.red-button:hover {
  background-color: #cc0000;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
}
.favourites-modal .ant-select {
  width: 100%;
}
.favourites-modal .ant-modal-footer {
  padding-top: 0;
}
.favourites-modal .ant-modal-header {
  padding-left: 2em;
  padding-bottom: 1em;
}

.recovery-button .ant-btn {
  color: #13bee8;
  border-color: #13bee8;
}
.reset-password .back-to-login:hover {
  text-decoration: underline;
}
.ant-row.ant-form-item.back-to-login span {
  font-size: 12px;
}
form.ant-form.ant-form-inline.reset-password-form {
  height: auto;
}
.button-container.ant-row.ant-form-item button,
.button-container.ant-row.ant-form-item {
  width: 12em;
  height: 2.2em;
  border-radius: 0.3em;
  background: transparent;
  margin-top: 1.5em;
  color: #13bee8;
  border-color: #13bee8;
}
.login-form .ant-input {
  text-align: center;
}
.reset-password .back-to-login {
  color: #13bee8;
  margin-top: 2em;
}
.button-container.ant-row.ant-form-item button:hover {
  background: #13bee8;
  color: #fff;
  border-color: #fff;
}
.ant-row.ant-form-item.recovery-password {
  float: right;
  text-align: right;
}
.link-container {
  margin: 0 2em;
  width: 100%;
}

.login-info {
  margin: 0 auto;
  max-width: 29em;
  display: flex;
  background: #052533;
  color: #fff;
  padding: 1em 0;
  border-bottom-left-radius: 0.4em;
  border-bottom-right-radius: 0.4em;
  height: 7.5em;
}
.login-info-text a.customer-care {
  color: #fff;
}
.login-info-text a.customer-care:hover {
  cursor: pointer;
  text-decoration: underline;
}

span.login-info-text {
  font-size: 13px;
  flex-direction: column;
  display: inline;
  text-align: center;
  padding-top: 1em;
  width: 100%;
}

.login-info-text strong {
  display: inline;
}

.login-info .close-x {
  width: 2em;
  margin: 0;
  margin-top: 0.5em;
  margin-left: 0.8em;
}
.login-info .close-x svg path {
  fill: #fff;
}

.registration-form .ant-input:hover,
.login-form .ant-input:hover {
  border-color: #13bee8;
}

form.registration-form {
  height: 50em;
  max-width: 30em;
  margin: 0 auto;
  padding: 3em;
  background: #fff;
  border-radius: 0.4em;
  text-align: center;
  margin-top: 3em;
  -webkit-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.38);
  -moz-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.38);
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.38);
}

form.ant-form.ant-form-inline.login-form {
  height: 26em;
}
.sharp-bottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

form.ant-form.ant-form-inline {
  max-width: 29em;
  margin: 0 auto;
  height: 28em;
  background: #fff;
  border-radius: 0.4em;
  text-align: center;
  margin-top: 2em;
  -webkit-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.38);
  -moz-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.38);
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.38);
}
.welcome-message {
  margin-bottom: 1em;
  padding-top: 3em;
}
.welcome-subtitle,
.welcome-title {
  margin-bottom: 0;
  color: #13bee8;
}
.welcome-title {
  font-weight: bold;
  font-size: 22px;
}
.welcome-subtitle {
  font-size: 13px;
}
/* color: #13bee8; */
.ant-form-inline .ant-form-item {
  display: block;
  margin-right: 0;
}
.registration-form .ant-form-item {
  margin-bottom: 1em;
}
.registration-form .ant-form-item-control-wrapper,
.login-form .ant-form-item-control-wrapper {
  width: 100%;
}
.reset-password .ant-form-item-control-wrapper {
  width: 80%;
}
.reset-password.change .ant-form-item-control-wrapper {
  width: auto;
}
.registration-form .ant-form-explain,
.reset-password .ant-form-explain,
.login-form .ant-form-explain {
  display: none;
  position: absolute;
  border-radius: 5px;
  padding: 5px;
  border: solid 1px #f5222d;
  color: #f5222d;
  background: #fff;
  z-index: 100;
  top: 4.74px;
  left: -190px;
  width: 39%;
  text-align: center;
}

.triangle-right {
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-left: 100px solid red;
  border-bottom: 50px solid transparent;
}
.reset-password .ant-row.ant-form-item.ant-form-item-with-help,
.login-form .ant-row.ant-form-item.ant-form-item-with-help {
  margin-bottom: 0px;
}

.detail-meta-wrapper p {
  margin-bottom: 0;
}

a.ant-btn.ant-btn-primary {
  background-color: #13bee8;
  border-color: #13bee8;
}
a.ant-btn.ant-btn-primary:hover {
  background-color: #13bee8;
  border-color: #13bee8;
}
button.ant-btn.ant-btn-button,
a.ant-btn.ant-btn-primary {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
}
button.ant-btn.ant-btn-button:hover,
a.ant-btn.ant-btn-primary:hover {
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -1px rgba(0, 0, 0, 0.2);
}

.login-form .ant-btn-primary {
  border: solid 1px #ffffff5e;
}
.registration-form a:hover,
.login-form a:hover {
  text-decoration: underline;
}

button.ant-btn.ant-btn-primary {
  float: right;
  margin-top: 3px;
}
.button-container .ant-form-item {
  float: right;
}
.reset-password .button-container .ant-form-item {
  float: none;
  margin: 0 auto;
}
.ant-form-item-children {
  font-size: 10px;
}
.login-form .ant-form-item-children {
  font-size: 13px;
}
.login-form-footer {
  height: 3em;
  margin-top: 5em;
  display: flex;
  justify-content: space-evenly;
}

.login-form-body {
  margin-top: 2em;
  display: block;
}

.motto {
  text-align: center;
  color: #fff;
  font-size: 23px;
}

.reset-password .error-message {
  margin: 0 auto;
  width: 23.5%;
  height: 100px;
  text-align: center;
  background: #e57373;
  color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.form-wrapper .error-message {
  margin: 0 auto;
  width: 27em;
  height: 3em;
  text-align: center;
  line-height: 1em;
  background: #e57373;
  color: #fff;
  padding: 1em;
  border-radius: 10px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
}
.success-message {
  margin: 0 auto;
  margin-top: 2em;
  width: 27em;
  height: 3em;
  text-align: center;
  line-height: 1em;
  background: #00d32d;
  color: #fff;
  padding: 1em;
  border-radius: 10px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
}
.reset-password .success-message {
  margin: 0 auto;
  margin-top: 20px;
  width: 23.5%;
  height: 100px;
  text-align: center;
  background: #f9fbe7;
  color: #000;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
}
.reset-password .ant-form-item-control {
  margin-bottom: 10px;
}
.login-form .ant-form-item-control {
  line-height: 1;
}

/* SVG */

.anticon .anticon-user svg,
.anticon .anticon-lock svg {
  color: #000;
}

.MuiAutocomplete-noOptions {
  display: none;
}

.injury {
  margin-top: 2em;
}
.injury .ant-table-body .ant-table-tbody td {
  border-bottom: 0;
}
.injury .ant-table-body .ant-table-thead tr,
.injury .ant-table-body .ant-table-thead th {
  border-bottom: 0 !important;
}

/* LIST ELEMENTS */
.list-element {
  transition: 200ms ease;
}
.list-element a {
  color: #000;
  padding: 5px 0px 5px 15px;
}

.list-element:hover {
  background: #13bee8;
  color: #ffffff;
  cursor: pointer;
}
.list-element:hover .icons-container svg {
  fill: white;
}
.list-element.active a,
.list-element:hover a {
  color: #ffffff;
}

/* SCROLLBAR */
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #92929a;
}

::-webkit-scrollbar {
  width: 4px;
  height: 8px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}
/* STREAM */

.cross-feed-content .no-streams {
  padding: 220px 190px;
}

.stream-fader {
  width: 94.95%;
  margin-left: -12px;
  margin-top: 122px;
  background: linear-gradient(rgba(245, 245, 245, 0), #fff);
}

.two-cols {
  width: 50%;
}

#scroller {
  width: 100%;
}

/* TOP NAV BAR */

/* MODAL */

/* FAKE BUTTON */
.fake-button {
  color: #13bee8;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  text-align: left;
}
.fake-button:hover {
  cursor: pointer;
}

button.fake-button:hover {
  cursor: pointer;
}

/* LOGOUT */

.logout .fake-button:hover {
  cursor: pointer;
}

.logout span {
  margin-left: 10px;
}

.logout:hover span {
  background-color: rgba(0, 0, 0, 0.1);
  text-decoration: underline;
}

/* REGISTRATION FORM */
.registration-form {
  max-width: 40em;
  margin: 0 auto;
  padding: 1em;
}
.reset-password a.ant-btn.ant-btn-primary {
  line-height: 1.499;
}
.registration-form .ant-select {
  border: none !important;
}

.registration-form .ant-select-dropdown-menu-item:hover {
  background-color: #358cdd45;
}
.livestream-container,
.two-cols .notificaion-wrapper,
.notificaion-wrapper,
#scroller {
  background: #f7f7f7;
}

.ant-table-small {
  border: 0;
}

.ant-tabs-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border: none;
}
.ant-drawer-body {
  background-color: #f7f7f7;
}
.streams-col-wrapper {
  background: #fff;
  border: none;
  margin-bottom: 1em;
}
.streams-row-wrapper {
  background: #fff;
  border: none;
  margin-bottom: 1em;
}

.login-form .ant-btn-primary {
  color: #13bee8;
  background-color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.login-form .ant-btn-primary:hover {
  color: #13bee8;
  background-color: #fff;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
/* VERTICAL MENU */
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical .ant-menu-item {
  margin: 0;
  background-color: #052533;
}

.ant-menu-dark .ant-menu-item:hover {
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
}
.top-nav-bar {
  display: inline-flex;
  align-items: center;
  background-color: #052533;
}
.top-nav-bar .logo {
  width: 20em;
  margin-left: 3em;
}

.customer-care:hover {
  cursor: help;
}

.customer-care img {
  height: 1.5em;
  line-height: 4.5em;
  font-size: 14px;
  margin-left: 20px;
}
.ant-modal .ant-input:focus {
  border-top: none;
  border-left: none;
  border-right: none;
  box-shadow: none;
  border-radius: 0;
  border-right-width: 0px !important;
  border-bottom: 1px solid #fff;
}
.suggestions-modal-autocomplete .list-element a div {
  width: 2.5em;
  height: 2.5em;
  margin-right: 0.5em;
}
.section-label-container {
  font-size: 14px;
  margin-bottom: 0.5em;
}
.suggestion-section {
  font-size: 14px;
  margin-bottom: 0.5em;
}
.suggestions-modal-autocomplete .list-element img.profile-image {
  max-width: 2.5em;
  min-width: 2.5em;
  height: 2.5em;
}
.suggestions-modal-autocomplete .ant-modal-body span {
  font-size: 14px;
}
.short-info-wrapper {
  display: inline-block;
  padding: 0 1em;
  width: 80%;
  vertical-align: super;
}
.suggestions-modal-autocomplete .ant-input:focus {
  border-bottom: 1px solid #8f8f8f78 !important;
}
.suggestions-modal-autocomplete .ant-modal-content {
  height: 36em;
}

.suggestions-modal-autocomplete .ant-modal-header {
  border-bottom: 1px solid #8f8f8f78;
  padding: 11px 0px 11px 24px;
}
.suggestions-modal-autocomplete .ant-select-auto-complete.ant-select .ant-input {
  border-bottom: solid 1px #8f8f8f78;
  border-radius: 0;
}

.suggestions-modal-autocomplete .ant-select-auto-complete.ant-select .ant-input:focus,
.ant-select-auto-complete.ant-select .ant-input:hover {
  border-bottom: solid 1px #8f8f8f78;
}

.ant-select-selection.ant-select-selection--single {
  background-color: transparent;
}
span.ant-input-suffix button.ant-btn.search-btn.ant-btn-primary.ant-btn-lg {
  background: transparent;
  border: none;
}

.ant-select-selection__placeholder {
  font-size: 17px;
}

.ant-tabs.ant-tabs-top.ant-tabs-line {
  width: 100%;
}
.ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane,
.ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane {
  flex: 0 0 auto;
}

.suggestions-modal-autocomplete .ant-modal-content .ant-modal-close {
  display: none;
}
.suggestion-search-wrapper i:hover,
.suggestion-search-wrapper i {
  color: #fff;
}
.suggestion-search-wrapper i:hover .ant-input {
  border: solid 1px #fff;
}
.suggestions-modal-autocomplete .ant-modal-body {
  overflow: auto;
}

.suggestions-modal-autocomplete .list-element {
  height: 2.5rem;
}

/* GO BACK BUTTON */
.go-back button.ant-btn.ant-btn-button:hover,
.go-back button.ant-btn.ant-btn-button {
  background-color: #f7f7f7;
  border-color: #f7f7f7;
  color: #052533;
  box-shadow: none;
  text-transform: capitalize;
}

.go-back {
  position: fixed;
  margin-top: 0;
}
.info-drawer .ant-drawer-header {
  height: 7em;
}
/* FAVOURITES MODAL */
.info-drawer .title,
.filters-modal .title,
.favourites-modal .title {
  font-size: 21px;
  font-weight: bold;
}
.info-drawer .subtitle,
.filters-modal .subtitle,
.favourites-modal .subtitle {
  font-size: 12px;
  display: block;
}
.info-drawer p.label,
.filters-modal p.label,
.favourites-modal p.label {
  font-size: 14px;
  font-weight: bold;
  margin: 3px;
  color: #c7c7c7;
  padding-top: 18px;
}
.info-drawer .left-top-col,
.filters-modal .left-top-col,
.favourites-modal .left-top-col {
  float: left;
}
.info-drawer .ant-drawer-content-wrapper {
  width: 31em !important;
  height: 100% !important;
  top: 0 !important;
}
.info-drawer .title-row {
  line-height: 1.5;
}
.info-drawer .top-right-icon-container {
  float: right;
  margin-right: 0.3em;
}
.info-drawer .ant-drawer-body h1.title {
  display: block;
  width: 100%;
}
.info-drawer .ant-drawer-body span.description {
  width: 100%;
  display: block;
}
.info-col {
  padding: 1em;
}
.info-drawer .ant-drawer-body {
  overflow: auto;
}
.escaping-details.info-col {
  margin-bottom: 5em;
}

.filters-modal .top-right-icon-container,
.favourites-modal .top-right-icon-container {
  font-size: 25px;
  float: right;
  margin-right: 0.3em;
}
.filters-modal .ant-modal-title .title-row,
.favourites-modal .ant-modal-title .title-row {
  font-size: 30px;
  padding-top: 28px;
}
.not-visible {
  visibility: hidden;
}

.hidden {
  display: none;
}
i.anticon.anticon-filter {
  margin-left: 20px;
  margin-top: 1em;
}
.suggestions-modal-autocomplete .info-icon {
  width: 1.5em;
}
.suggestions-modal-autocomplete .close-x,
.favourites-modal .close-x,
.filters-modal .close-x {
  width: 1.5em;
}
.info-advanced-search {
  background: #052533;
  color: #fff;
  padding: 1em;
}
.info-icon {
  vertical-align: unset;
}
.info-advanced-search span {
  text-align: left;
}
.info-advanced-search p {
  margin-left: 0.2em;
}
.info-advanced-search p.clickable {
  margin: 0;
}

.info-advanced-search p,
.info-advanced-search .close-x,
.suggestions-modal-autocomplete .info-icon {
  display: inline-block;
}
.info-advanced-search p.clickable {
  color: #13bee8;
  text-decoration: underline;
}
.info-advanced-search p.clickable:hover {
  cursor: pointer;
}
.favourites-modal .ant-modal-close-x {
  font-size: 30px;
}
.favourites-modal .ant-modal-body {
  padding: 1.5em;
  padding-top: 0;
  padding-bottom: 0;
}
button.ant-btn.modal.grey-button {
  background: #92929a;
  color: #fff;
}
button.ant-btn.modal.grey-button {
  background: #787881;
}
button.ant-btn.modal.red-button:hover,
button.ant-btn.modal.grey-button:hover {
  box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -1px rgba(0, 0, 0, 0.2);
  border-color: transparent;
}
button.ant-btn.modal.red-button {
  background: red;
  color: #fff;
}

button.ant-btn.modal.red-button:hover {
  background: #cc0000;
}
.ant-modal-body {
  padding: 0;
}
/* end favourites modal */

/* IG post */
.PrimaryCTA {
  padding-left: 12px;
  padding-right: 12px;
  margin-left: 0;
  margin-right: 0;
}

.social-post-modal .ant-modal-close-x {
  width: 90px;
  height: 90px;
  font-size: 30px;
  margin-top: 10px;
  font-weight: bolder;
}
/* end IG post*/

.filter-bar button.ant-btn.ant-btn-primary {
  top: 5px;
  margin-left: 10px;
}

/* TAG CLOUD */

.hashtag-cloud .tag-cloud span.tag-cloud-tag {
  color: #13bee8 !important;
}
/* END OF TAG CLOUD */

.preview-image {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
}
.charts-container {
  color: #fff;
  text-align: center;
}
.two-charts-wrapper {
  height: 100%;
  border-top: 1px solid #d5d5d5;
}
.hashtag-detail-picture svg path,
.fulltext-detail-picture svg path {
  fill: #052533;
}

.detail-wrapper .detail-picture .custom-icon svg {
  width: 3em;
}

.profile-icon {
  width: 3.5em;
  margin-right: 15px;
}
.suggestion-container {
  padding-top: 20px;
}
.suggestion-section {
  padding-left: 20px;
  color: #9e9e9e;
}
.description-link-container {
  margin-left: -4.5em;
  margin-top: 10px;
}
.section-title {
  padding-left: 15px;
  color: #9e9e9e;
  font-weight: 600;
  text-transform: uppercase;
}
.list-wrapper {
  margin: 10px 0;
}

.blue-nav-button i {
  color: #fff;
}
.blue-nav-button img {
  width: 2em;
}

.ant-tabs-nav .ant-tabs-tab:hover {
  color: #13bee8;
}
.ant-tabs-tab[aria-selected='false'] {
  color: #052533;
}

.filters-toggle {
  display: flex;
  flex-direction: row;
}
.profile-icon svg path {
  fill: #052533;
}
.mobile.filter-toggle {
  display: flex;
  margin-left: 1em;
  flex-direction: row;
}

.mobile .filter-svg {
  padding-top: 0.1em;
  width: 1.2em;
  display: inline-block;
}

.detail-picture svg path {
  fill: #052533;
}
.filter-svg svg path {
  fill: #052533;
}
.filters-toggle > div {
  height: 3.7em;
  line-height: 3.7;
}
.filter-svg {
  width: 1.3em;
  display: inline-block;
}
button.ant-btn.filter.filters-button:hover {
  background: #13bee8;
}
button.ant-btn.filter.filters-button:hover svg path {
  /* fill: #13bee8; */
  fill: #fff;
}
button.ant-btn.filter.filters-button:hover span {
  /* color: #13bee8; */
  color: #fff;
}
.filters-button span {
  vertical-align: text-bottom;
  margin-left: 0.5em;
  color: #052533;
}

button.ant-btn.filter.filters-button {
  height: 33px;
  margin-top: 0.7em;
  background: #f7f7f7;
  border: none !important;
}

.reset-x {
  width: 2em;
  height: 2em;
  margin-left: 2em;
  float: left;
  padding-top: 0.8em;
}
.reset span {
  margin-left: 1em;
  color: #13bee8;
  line-height: 3.7;
}
.filters-toggle .reset {
  display: inline-block;
  width: 13em;
}
.filters-toggle .reset svg path {
  fill: #13bee8;
}
.filters-toggle svg path {
  fill: #052533;
}
.filters-toggle svg:hover path {
  fill: #13bee8;
}
.filters-toggle:hover {
  cursor: pointer;
}
.language {
  background: #fff;
}

.video-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-placeholder > div {
  width: 50%;
  height: 50%;
}

.video-placeholder svg {
  fill: white;
}

.social-counter {
  /* margin-left: 2.5em; */
  font-weight: 600;
}
.social-counter span {
  margin-left: 0.5em;
}
.stream-row.tw:hover {
  border-left-color: #0f0f0f !important;
}
.stream-row.tw .video-placeholder {
  background-color: #0f0f0f;
}
span.tw svg,
.social-counter#twitter .anticon.anticon-twitter {
  color: #0f0f0f;
}
.stream-row.ig:hover {
  border-left-color: #3f729b !important;
}
.stream-row.ig .video-placeholder {
  background-color: #3f729b;
}
div#instagram svg path {
  fill: #3f729b;
}
span.ig svg path {
  fill: #3f729b;
}
i.anticon.anticon-instagram,
.social-counter#instagram .anticon.anticon-instagram {
  fill: #3f729b;
}
.stream-row.fb:hover {
  border-left-color: #3b5998 !important;
}
.stream-row.fb .video-placeholder {
  background-color: #3b5998;
}
div#facebook svg path {
  fill: #3b5998;
}
span.fb svg,
.social-counter#facebook svg.svg-inline--fa.fa-facebook-f.fa-w-9 {
  color: #3b5998;
}
.stream-row.rss:hover {
  border-left-color: #f99c38 !important;
}
.stream-row.rss .video-placeholder {
  background-color: #ff9c38;
}
span.rss svg {
  color: #db7d36;
}
li.ant-dropdown-menu-item.active {
  background: #d5d5d5;
}

li.ant-dropdown-menu-item i {
  color: #00000000;
}
li.ant-dropdown-menu-item.active i {
  color: #000;
}
.ant-dropdown-menu-item > .anticon:first-child,
.ant-dropdown-menu-submenu-title > .anticon:first-child {
  padding: 0.5em;
  margin: 0;
}

/* span.rss svg */

.ant-drawer-close {
  left: 0;
}
.list-element {
  height: 4em;
}
.static-suggestions {
  margin-bottom: 1em;
}
.list-element img.profile-image {
  max-width: 3.5em;
  min-width: 3.5em;
  object-fit: contain;
  margin-right: 15px;
  vertical-align: bottom;
  height: 3.5em;
}
.list-element.active a svg path {
  fill: #fff;
}

span.user-first-name {
  padding-right: 0.3em;
}
.ant-drawer-wrapper-body {
  overflow: hidden !important;
}

.suggestions-modal-autocomplete .ant-modal-content {
  height: 36em;
}

.social-post-modal .ant-modal-content {
  padding: 1em 2em 3em;
  text-align: left;
}

svg.svg-inline--fa.fa-link.fa-w-16 {
  margin-right: 0.5em;
}

.stream-message-wrapper {
  height: calc(100% - 1.5em);
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5em;
}
span.rss,
span.fb,
span.ig,
span.tw {
  color: #acacac;
  font-size: 0.75em;
  height: 1.2em;
  display: flex;
}

span.posted-at {
  margin-left: 0.2em;
  font-weight: normal;
}

.profile-name {
  width: 100%;
}
.stream-message {
  height: 100%;
  margin-top: 4px;
}

.bookmarked-wrapper #scroller,
.bookmarked-wrapper #scroller > div,
.bookmarked-wrapper .livestream-container {
  height: 100%;
}
.bookmarked-wrapper .livestream-container > div {
  margin: 0 !important;
}
.bookmarked-wrapper,
.bookmarked-wrapper .scrolling-container {
  height: 100% !important;
}
.bookmarked-body {
  background: #f7f7f7;
}
.bookmarked-icon {
  width: 3em;
}
.bookmarked-icon svg path {
  fill: #052533;
}
/* .svg-inline--fa.fa-w-16 {
  margin-top: 2px;
} */
.list-element a {
  display: inline-flex;
  align-items: center;
}
.list-element a div {
  width: 3em;
  height: 3em;
  margin-right: 1em;
}
.list-element a div div svg path {
  fill: #052533;
}
.list-element:hover a div div svg path {
  fill: #fff;
}

.svg-inline--fa.fa-w-16 {
  height: 1.2em;
  width: auto;
}
.preview-image,
span.two-cols-preview-image {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
  width: 100%;
  display: block;
  border: 1px solid #d5d5d5;
}

.loader {
  background: #fff;
}
.loading {
  width: 100%;
  margin: 0 auto;
}

.ant-spin-dot {
  position: relative;
  display: inline-block;
  font-size: 20px;
  width: 25px;
  height: 25px;
  top: 1.5em;
}

i.anticon.anticon-star.star-ico:hover {
  cursor: pointer;
}

.ant-menu-dark.ant-menu-horizontal {
  height: 100%;
}

li.ant-menu-submenu.ant-menu-submenu-horizontal.flag-menu {
  height: 100%;
}

.ant-menu-submenu-title {
  height: 100%;
}

.right-side-container {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  width: 100%;
  margin-right: 3em;
}
.ant-dropdown-placement-bottomCenter .ant-dropdown-menu {
  background-color: #052533 !important;
}
.ant-dropdown-placement-bottomCenter .ant-dropdown-menu-item:hover {
  background-color: #fff1;
}

.ant-menu-vertical.ant-menu-sub .ant-menu-item {
  border-radius: 5px;
}

.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  background: #052533;
  border-radius: 5px;
}
.suggestions-modal,
.suggestions-modal > div,
.suggestion-search-wrapper {
  height: 100%;
}

.ant-menu-vertical.ant-menu-sub,
.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub {
  min-width: 1.9em;
}
.ant-submenu-popup {
  left: 658.875px !important;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  vertical-align: inherit;
}
.rss-embed-wrapper {
  padding: 2em;
}

.social-post-modal .ant-modal-body {
  max-height: 39em;
  overflow-y: auto;
  background: #f7f7f7;
  /* border-bottom: 1px solid #cfc7cf; */
}
.active {
  background: #13bee8;
  cursor: pointer;
}
#autocomplete .list-element.active {
  background: transparent;
}
#autocomplete .list-element a:hover {
  background: #95cdfb;
}
#autocomplete .list-element.active:hover {
  background: #13bee8;
  cursor: pointer;
}
#autocomplete .list-element.active:hover a {
  color: #fff;
}
#autocomplete .list-element.active a {
  color: #052533;
}
.ant-modal-body .active .list-element a {
  color: #fff;
}
.disabled.hashtag-cloud .tag-cloud span.tag-cloud-tag,
.disabled.hashtag-cloud .tag-cloud span.tag-cloud-tag:hover {
  cursor: default;
  text-decoration: none;
  color: black !important;
}
.hashtag-cloud .tag-cloud span.tag-cloud-tag:hover {
  cursor: pointer;
  text-decoration: underline;
}
.cross-feed-drawer {
  top: 0 !important;
  left: auto !important;
  right: 0 !important;
  width: 100%;
  height: 100% !important;
}
.cross-feed-drawer .streams-row-wrapper.no-streams {
  margin: 0;
  width: 100%;
}
.stream-source-name p {
  margin: 0;
  padding: 0;
  display: inline;
  font-size: 12px;
}
.other-owners {
  font-size: 0.75em;
  color: #acacac;
  /* margin-left: .25em; */
}
.no-content-available {
  text-align: center;
  margin-top: 2em;
}
.favourites-modal .apply-filters {
  display: inline-flex;
  padding: 5px 1em;
  width: 12.4em;
  justify-content: center;
  margin-right: 0.2em;
  margin-left: 0.5em;
  font-size: 12px;
  border-radius: 2px;
}
.unfollow {
  display: inline-flex;
  padding: 5px 1em;
  width: 12.5em;
  justify-content: center;
  margin-right: 0.5em;
  margin-left: 0em;
  font-size: 12px;
  border-radius: 2px;
}
.reset-filters,
.apply-filters {
  display: inline-flex;
  padding: 5px 1em;
  width: 12.8em;
  justify-content: center;
  margin-right: 0.5em;
  margin-left: 0.5em;
  font-size: 12px;
  border-radius: 2px;
}

.unfollow,
.reset-filters {
  color: #052533;
  background: #f7f7f7;
}
.apply-filters {
  color: #fff;
  background-color: #13bee8;
  border: 1px solid #13bee8;
}
.apply-filters:hover {
  cursor: pointer;
}
.unfollow:hover,
.reset-filters:hover {
  cursor: pointer;
  background: #052533;
  color: #fff;
}
.filters-modal .ant-modal-footer {
  padding: 25px;
  text-align: right;
  font-weight: bold;
  border-top: solid 1px #d5d5d5;
}

.all-owners {
  cursor: context-menu;
}
p.plus-others {
  margin-left: 0.5em;
}
.other-owners .pipe {
  padding: 0 0.25em;
}
.no-scroll .scrolling-container,
.streams-row-wrapper.no-scroll {
  overflow-y: hidden !important;
}
.streams-row-wrapper.no-streams {
  width: 97%;
  margin: 0 auto;
}
.streams-row-wrapper .stream-skeleton {
  background: #f7f7f7;
}
.streams-row-wrapper .stream-skeleton .stream-row {
  margin-bottom: 1em;
  background: #fff;
}
.no-more-content-container.stream-row {
  width: 99%;
  background: #fff;
  border: none;
  /* border: 1px solid #c2c2c6; */
}
.no-more-content {
  padding: 2em;
  height: 100%;
  text-align: center;
  line-height: 4;
}

.no-more-content-container.stream-row:hover {
  border-left: 1px solid #c2c2c6 !important;
  cursor: default;
}
.active .team-name {
  color: #fff;
}

.fulltext-detail-wrapper .meta-container {
  margin-top: 20px;
}

/* SOCIAL SHARE COLORS */
.top-right-modal {
  display: inherit;
  margin: 0 0 0 auto;
}
.close-x path {
  fill: #052533;
}
.top-right-icon-container {
  align-items: center;
}
.filters-modal .top-right-icon-container {
  align-items: flex-end;
}
.share-on-social path,
.modal-top-action-container path,
.linkedin_share-button rect,
.linkedin_share-button path,
.email_share-button path,
.whatsapp_share-button path,
.twitter_share-button path,
.facebook_share-button path,
.share-button path {
  fill: #cfc7cf;
}
/* .share-on-social.open path,
.share-on-social.active path,
.share-on-social:hover path, */
.modal-top-action-container.saved path,
.modal-top-action-container:hover path,
.close-x:hover path,
.linkedin_share-button:hover rect,
.linkedin_share-button:hover path,
.email_share-button:hover path,
.whatsapp_share-button:hover path,
.twitter_share-button:hover path,
.facebook_share-button:hover path,
.share-button:hover path {
  fill: #13bee8;
}

/* .share-on-social:hover, */
.modal-top-action-container:hover,
.close-x:hover,
.linkedin_share-button:hover,
.linkedin_share-button:hover,
.email_share-button:hover,
.whatsapp_share-button:hover,
.twitter_share-button:hover,
.facebook_share-button:hover,
.share-button:hover {
  cursor: pointer;
}

.modal-top-action-container.saved,
.modal-top-action-container {
  width: 1.5em;
}
.modal-top-action-container.download {
  margin-right: 15px;
}
.modal-top-action-container a {
  display: contents;
}

.ant-modal.social-post-modal {
  max-width: 75em !important;
}
.social-post-modal .ant-modal-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
  padding: 0.3em 1.5em 0 1.5em;
  border-radius: 0;
}
.social-post-modal.tw .ant-modal-body,
.social-post-modal.tw .ant-modal-title {
  border: none;
}
.social-post-modal.tw .ant-modal-body {
  background: #f7f7f7;
}

.social-post-modal .ant-modal-header {
  padding: 0;
}
.share-buttons-container {
  /* margin-top: .5em; */
  position: absolute;
  right: -4em;
  background: #fff;
  top: 5.6em;
}
.social-post-modal .ant-modal-content {
  padding: 0;
  text-align: left;
}
.SocialMediaShareButton.SocialMediaShareButton--email.email_share-button,
.SocialMediaShareButton.SocialMediaShareButton--whatsapp.whatsapp_share-button,
.SocialMediaShareButton.SocialMediaShareButton--twitter.twitter_share-button,
.SocialMediaShareButton.SocialMediaShareButton--linkedin.linkedin_share-button,
.SocialMediaShareButton.SocialMediaShareButton--facebook.facebook_share-button,
.share-button {
  width: 3em;
  padding: 1em;
  border-bottom: 1px solid #cfc7cf;
}

.social-post-modal .ant-modal-footer a {
  font-size: 14px;
}
.title-row img {
  border-radius: 2em;
}
.ant-modal-content {
  border-radius: 1px;
}
.ant-modal-footer {
  font-size: 1.4em;
  padding: 1em 1.5em;
  /* padding: 2.9em; */
}
/* PROVATO IN FULL HD 1920 X 1080 */
.ant-modal-body .fader {
  /* width: 31%; */
  background: linear-gradient(rgba(245, 245, 245, 0), #f7f7f7);
  /* width: 59.6em; */
  margin-top: -2em;
  height: 3em;
}
.ant-tabs-extra-content {
  flex: 0 0 auto;
  line-height: 3.2;
}
.ant-tabs-nav-container {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
}

.ant-modal-body > div > .twitter-widget {
  margin: 0 auto !important;
}

.ant-modal-body > div > .twitter-tweet-rendered {
  align-items: center;
  justify-content: center;
  max-width: 100% !important;
}

.ant-modal-body > div > .twitter-tweet-rendered iframe {
  flex-grow: 0 !important;
  width: 550px !important;
}

/* .share-on-social, */
.close-x {
  width: 2em;
  margin: 0 0 0 auto;
}

.share-on-social {
  /* width: 2em; */
  width: 3em;
  padding: 0.7em;
  border-bottom: 1px solid #cfc7cf;
  background: #052533;
}
.share-on-social svg path {
  fill: #fff;
}

.close-x {
  margin-left: 2em;
}

/* .social-share-container {
  display: inline-flex;
  justify-content: flex-end;
} */

.ant-modal-title span.stream-source-type {
  display: block;
  font-size: 0.6em;
  line-height: 1.2em;
  margin-top: 0.2em;
}
.ant-modal-title a {
  font-size: 0.9em;
}
.favourites-modal,
.filters-modal {
  width: 50em !important;
}

.filters-modal .filter-table {
  padding: 1em;
}
.filter-table .wrapper {
  padding-bottom: 0.5em;
}
.filter-table .wrapper .title {
  color: #d7d7d7;
  font-weight: bold;
  text-align: left;
  padding-bottom: 1em;
  margin-left: 0.3em;
}
.favourites-modal .filter-table .wrapper .title {
  margin-left: 0.3em;
}
.favourites-modal .filter-table {
  padding-top: 1em;
}
.ant-modal-body .filter-table .wrapper .title span {
  font-size: 17px;
}
.filter-table .wrapper .grid {
  width: 100%;
}
.favourites-modal .push-value,
.favourites-modal .digest-value {
  display: inline-flex;
  border: solid 1px #c7c7c7;
  color: #c7c7c7;
  background-color: #fff;
  padding: 3px 1em;
  width: 12.8em;
  justify-content: center;
  margin-right: 0.5em;
  margin-left: 0.5em;
  margin-bottom: 1em;
  font-size: 12px;
  border-radius: 2px;
}
.filters-modal .ant-modal-header {
  padding-bottom: 2.5em;
  border-bottom: solid 1px #d9d9d9;
}

.filter-table .wrapper .grid .filter {
  display: inline-flex;
  border: solid 1px #b7b7b7;
  color: #b7b7b7;
  background-color: #fff;
  padding: 3px 1em;
  width: 12.8em;
  justify-content: center;
  margin-right: 0.5em;
  margin-left: 0.5em;
  margin-bottom: 1em;
  font-size: 12px;
  border-radius: 2px;
}
.favourites-modal .push-value.selected,
.favourites-modal .digest-value.selected,
.favourites-modal .push-value:hover,
.favourites-modal .digest-value:hover,
.filter-table .wrapper .grid .filter:hover,
.filter-table .wrapper .grid .filter.active {
  background-color: #13bee8;
  border: solid 1px #13bee8;
  cursor: pointer;
  color: #fff;
}
.favourites-modal-footer {
  text-align: right;
}
.uiScaledImageContainer.fbStoryAttachmentImage {
  margin: 0 auto;
}
.leaderboard .labels {
  margin-top: 2em;
  margin-bottom: 2em;
  color: #052533;
  font-weight: bold;
}
.label-row {
  margin-left: 1em;
  margin-bottom: 2em;
}
.top-assist-row,
.top-scorer-row,
.team-row {
  max-width: 46em;
  min-width: 34em;
  margin: 0 1em;
  margin-bottom: 0.5em;
  background: #fff;
  align-items: center;
}
.leaderboard .rank {
  background-color: #052533;
  color: #fff;
  width: 2em;
  height: 2em;
  text-align: center;
  padding: 0.3em;
}
.leaderboard .player-nationality img,
.leaderboard .player-pic img,
.leaderboard .team-logo img {
  width: 1.5em;
  height: 1.5em;
  margin-left: 1em;
}
.leaderboard .player-name,
.leaderboard .team-name {
  margin-left: 1em;
  color: #052533;
}
.leaderboard .player-stats,
.leaderboard .team-stats {
  display: inline-flex;
  flex-grow: 1;
  justify-content: flex-end;
}
.leaderboard .total-assists,
.leaderboard .total-scored,
.leaderboard .penalties-scored,
.leaderboard .points-earned,
.leaderboard .fb-followers,
.leaderboard .ig-followers,
.leaderboard .tw-followers,
.leaderboard .all-followers,
.leaderboard .match-played {
  padding: 0.25em 0.5em;
  border-left: solid 2px #f7f7f7;
  width: 61px;
  text-align: center;
}
.leaderboard .total-assists,
.leaderboard .total-scored,
.leaderboard .points-earned {
  color: #13bee8;
  width: auto;
}
.leaderboard .match-played {
  width: auto;
}

.stream-col {
  display: flex;
  flex-direction: column;
}
.title-wrapper {
  width: 100%;
}
.title-wrapper-row {
  height: 4em;
}
.scrolling-container {
  display: flex;
  flex-direction: column;
}

.success_modal .ant-modal-body {
  height: 20em;
  text-align: center;
  padding: 8em;
}

.icons-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 5px 15px;
  opacity: 0;
  transition: opacity 150ms ease;
}

.list-element:hover .icons-container {
  opacity: 1;
}

.icons-container > div {
  width: 18px;
  height: 18px;
}

.icons-container svg {
  fill: #cfc7cf;
}

.tag-cloud-tag {
  cursor: pointer;
}

.ant-table-expanded-row {
  background-color: white !important;
}

.modal-video .modal-video-body {
  max-width: 720px !important;
}

.icon-blue path {
  fill: #092532;
}

.icon-light-blue path {
  fill: #4abfe8;
}

.ant-table-row-expand-icon {
  margin-right: 0 !important;
}

.ant-table-row-level-0 {
  cursor: pointer;
}

.injury-icon {
  padding-left: 6px;
  margin-top: -1px;
}
